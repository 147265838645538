/*** Mobile Screens ***/
#sidebar{
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  float: right;
  position: relative;

  .Level-1{
    display: none;
    ul{
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 5px;
    border: none;
    outline: none !important;
    right: 5%;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: $textDark;
    &:hover{
      color: #2F3B67;
    }
  }
  .sidebar-heading{
    display: block;
    float: left;
    clear: both;
    width: 100%;
    a{
      padding: 5px 13% 5px 13%;
      display: block;
      text-decoration: none;

      font-weight: lighter;
      font-size: 1.8em;
      text-transform: uppercase;
      color: #0759a7;

    }
  }
  ul{
    ul{
      display: none;

      li{
        a{
          /*** Level Indentation 
          margin-left: 20px !important;
          ***/
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation 
            margin-left: 30px !important;
            ***/
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      .current{
          background-color: #bdd0e7;
          span{
            color: #0759a7 !important;
          }
      }
      a{
        display: block;
        padding: 5px 13% 5px 13%;
        text-decoration: none;
        @include transition($t1);
        font-weight: lighter;
        color: #5f5b54;
        background-color: rgba(0,0,0,0.0);

        span{
          color: #5f5b54;
          @include transition($t1);
        }
        &:hover span{
          color: #0759a7 !important;
        }
        &:hover{
          background-color: #bdd0e7;
        }
      }
    }
  }
}

.openLevel{
  background-color: rgba(0,0,0,0.05);
}


#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (min-width: 768px) {
  #sidebar{
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
